import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { FC } from "react";
import { useStore } from "../store/GlobalStoreContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  Label,
  Loader,
  Message,
  Modal,
  Radio,
  Tab,
  TextArea,
} from "semantic-ui-react";
import { IConfig, ICallTime, ITimeVal } from "../../../../src/types";
import moment from "moment-timezone";

const languages = [
  {
    key: "en-US",
    text: "English",
    value: "en-US",
  },
  {
    key: "de-DE",
    text: "German",
    value: "de-DE",
  },
  {
    key: "pl-PL",
    text: "Polish",
    value: "pl-PL",
  },
  {
    key: "fr-FR",
    text: "French",
    value: "fr-FR",
  },
  {
    key: "it-IT",
    text: "Italian",
    value: "it-IT",
  },
  {
    key: "es-ES",
    text: "Spanish",
    value: "es-ES",
  },
  {
    key: "pt-PT",
    text: "Portuguese",
    value: "pt-PT",
  },
  // {
  //   key: "ru-RU",
  //   text: "Russian",
  //   value: "ru-RU",
  // },
  {
    key: "nl-NL",
    text: "Dutch",
    value: "nl-NL",
  },
  {
    key: "sv-SE",
    text: "Swedish",
    value: "sv-SE",
  },
  {
    key: "no-NO",
    text: "Norwegian",
    value: "no-NO",
  },
  {
    key: "da-DK",
    text: "Danish",
    value: "da-DK",
  },
  {
    key: "fi-FI",
    text: "Finnish",
    value: "fi-FI",
  },
  {
    key: "tr-TR",
    text: "Turkish",
    value: "tr-TR",
  },
  {
    key: "ar-SA",
    text: "Arabic",
    value: "ar-SA",
  },
];

const transformPhrases = (value: {
  [key: string]: string[];
}): { [key: string]: string } => {
  const current = Object.fromEntries(
    Object.entries(value).map(([k, v]) => [k, v.join("\n")])
  );

  current.filler = current.filler ?? "Okay...\nUhm...\nHmm...\nUmm...";
  return current;
};

const InvTransfromPhrases = (value: {
  [key: string]: string;
}): { [key: string]: string[] } => {
  return Object.fromEntries(
    Object.entries(value).map(([k, v]) => [
      k,
      v
        .split("\n")
        .map((v) => v.trim())
        .filter((v) => v !== ""),
    ])
  );
};

const timezones = moment.tz.names().map((x) => ({
  key: x,
  text: x,
  value: x,
}));

const updateLanguageFormat = (language: string) => {
  if (language === "en") {
    return "en-US";
  }
  if (language === "de") {
    return "de-DE";
  }
  return language;
};

const ConfigEditorInternal: FC<{
  configId: string;
  config: IConfig;
  error: string | undefined;
  setError: (error: string | undefined) => void;
}> = observer(({ configId, config, error, setError }) => {
  const { configs, sip, token, sms } = useStore();
  const [language, setLanguage] = useState(
    updateLanguageFormat(config.language)
  );
  const [description, setDescription] = useState(config.description);
  const [location, setLocation] = useState(config.location);
  const [script, setScript] = useState(config.script);
  const [timezone, setTimezone] = useState(config.timezone);
  const [eventTypeId, setEventTypeId] = useState(config.eventTypeId);
  const [testLead, setTestLead] = useState(config.testLead);
  const [sms1, setSMS1] = useState(config.firstSend);
  const [sms2, setSMS2] = useState(config.secondSend);
  const [sms3, setSMS3] = useState(config.thirdSend);
  const [prompt, setPrompt] = useState(config.prompt ?? configs.Default.prompt);
  const [trailing_prompt, setTrailingPrompt] = useState(
    config.trailing_prompt ?? configs.Default.trailing_prompt ?? ""
  );
  const [isCalling, setIsCalling] = useState(false);
  const [smsReply, setSMSReply] = useState("");
  const [saveAsOpen, setSaveAsOpen] = useState(false);
  const [regionPhonePrefix, setRegionPhonePrefix] = useState(config.regionPhonePrefix ?? "+49");
  const [speaker, setSpeaker] = useState(
    config.speaker ?? "bJ2EakS3LaqGfBtFxaFu"
  );
  const [voice_model, setVoiceModel] = useState(config.voice_model ?? "f25");
  const navigate = useNavigate();
  const [saveAsName, setSaveAsName] = useState("");
  const [sourcePhone, setSourcePhone] = useState(config.sourcePhone ?? "");
  const [lastMockData, setLastMockData] = useState<{
    id: string;
    results: { output: any; transription: any } | undefined;
  }>();
  const [toCallNumber, setToCallNumber] = useState("");
  const [staticPhrases, setStaticPhrases] = useState(
    transformPhrases(
      config.staticPhrases ?? configs.Default.staticPhrases ?? {}
    )
  );

  const [schedulerEnabled, setSchedulerEnabled] = useState(
    config.schedulerEnabled
  );
  const [schedule, setSchedule] = useState<ICallTime>(config.schedule || {});

  useEffect(() => {
    const id = setInterval(() => {
      sms.Load();
      fetch("/api/v1/mock", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((x) =>
        x
          .json()
          .catch(() => {})
          .then((y) => setLastMockData(y))
      );
    }, 5000);

    return () => clearInterval(id);
  }, []);

  const GeneralEditor = () => {
    return (
      <Form>
        <Form.Field>
          <Label>Source phone number</Label>
          <Input
            placeholder="+4961314932205"
            value={sourcePhone ?? ""}
            onChange={(e, d) => setSourcePhone(d.value)}
          ></Input>
        </Form.Field>
        <Form.Field>
          <Label>Region phone prefix</Label>
          <Input
            placeholder="+49"
            value={regionPhonePrefix ?? ""}
            onChange={(e, d) => setRegionPhonePrefix(d.value)}
          ></Input>
        </Form.Field>
        <Form.Field>
          <Label>Language</Label>
          <Dropdown
            value={language}
            options={languages}
            onChange={(e, d) => setLanguage(d.value?.toString() ?? "en-US")}
          ></Dropdown>
        </Form.Field>
        <Form.Field>
          <Label>Speaker</Label>
          <Input
            value={speaker ?? ""}
            onChange={(e, d) => setSpeaker(d.value)}
          ></Input>
        </Form.Field>
        <Form.Field>
          <Label>Voice Model</Label>
          <Input
            value={voice_model ?? ""}
            onChange={(e, d) => setVoiceModel(d.value)}
          ></Input>
        </Form.Field>
        <Form.Field>
          <Label>Description</Label>
          <Input
            value={description}
            onChange={(e, d) => setDescription(d.value)}
          ></Input>
        </Form.Field>
        <Form.Field>
          <Label>Location</Label>
          <Input
            value={location}
            onChange={(e, d) => setLocation(d.value)}
          ></Input>
        </Form.Field>

        <Form.Field>
          <Label>Timezone</Label>
          <Dropdown
            search
            options={timezones}
            value={timezone}
            onChange={(e, d) => setTimezone(d.value)}
          ></Dropdown>
        </Form.Field>

        <Form.Field>
          <Label>Cal.com event id</Label>
          <Input
            value={eventTypeId}
            onChange={(e, d) => setEventTypeId(parseInt(d.value))}
            type="number"
          ></Input>
        </Form.Field>

        <Form.Field>
          <Label>Test lead in close.io</Label>
          <Input
            value={testLead}
            onChange={(e, d) => setTestLead(d.value)}
          ></Input>
        </Form.Field>
      </Form>
    );
  };

  const ScriptEditor = () => {
    return (
      <Form>
        <Form.Field>
          <Label>Script</Label>
          <TextArea
            rows={20}
            placeholder="Your script here"
            value={script}
            onChange={(e, d) => setScript(d.value?.toString() ?? "")}
          />
        </Form.Field>
      </Form>
    );
  };

  const PromptEditor = () => {
    return (
      <Form>
        <Form.Field>
          <Label>Prompt</Label>
          <TextArea
            rows={40}
            placeholder="Your prompt here"
            value={prompt}
            onChange={(e, d) => setPrompt(d.value?.toString() ?? "")}
          />
        </Form.Field>
      </Form>
    );
  };

  const TrailingPromptEditor = () => {
    return (
      <Form>
        <Form.Field>
          <Label>Trailing Prompt</Label>
          <TextArea
            rows={40}
            placeholder="Your trailing prompt here"
            value={trailing_prompt}
            onChange={(e, d) => setTrailingPrompt(d.value?.toString() ?? "")}
          />
        </Form.Field>
      </Form>
    );
  };

  const SMSEditor = () => {
    return (
      <Form>
        <Form.Field>
          <Label>First SMS, {sms1.length} characters</Label>
          <TextArea
            rows={6}
            placeholder="Your SMS here"
            value={sms1}
            onChange={(e, d) => setSMS1(d.value?.toString() ?? "")}
          />
        </Form.Field>
        <Form.Field>
          <Label>Second SMS (+1h), {sms2.length} characters</Label>
          <TextArea
            rows={6}
            placeholder="Your SMS here"
            value={sms2}
            onChange={(e, d) => setSMS2(d.value?.toString() ?? "")}
          />
        </Form.Field>
        <Form.Field>
          <Label>Thirds SMS (+24h), {sms3.length} characters</Label>
          <TextArea
            rows={6}
            placeholder="Your SMS here"
            value={sms3}
            onChange={(e, d) => setSMS3(d.value?.toString() ?? "")}
          />
        </Form.Field>
      </Form>
    );
  };

  const StaticPhrases = () => {
    return (
      <Form>
        {Object.entries(staticPhrases).map(([k, v]) => {
          return (
            <Form.Field>
              <Label>{k}</Label>
              <TextArea
                value={v}
                onChange={(e, d) => {
                  setStaticPhrases({
                    ...staticPhrases,
                    ...{ [k]: d.value?.toString() ?? "" },
                  });
                }}
              ></TextArea>
            </Form.Field>
          );
        })}
      </Form>
    );
  };

  const ScheduleEditor = () => {
    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"] as const;
    
    const addTimeRange = (day: keyof ICallTime) => {
      const newSchedule = { ...schedule };
      const dayRanges = newSchedule[day] || [];
      newSchedule[day] = [...dayRanges, { 
        start: { hour: 9, minute: 0 }, 
        end: { hour: 17, minute: 0 } 
      }];
      setSchedule(newSchedule);
    };

    const removeTimeRange = (day: keyof ICallTime, index: number) => {
      const newSchedule = { ...schedule };
      if (newSchedule[day]) {
        newSchedule[day] = newSchedule[day]?.filter((_, i) => i !== index);
        if (newSchedule[day]?.length === 0) {
          delete newSchedule[day];
        }
      }
      setSchedule(newSchedule);
    };

    const updateTimeRange = (day: keyof ICallTime, index: number, field: 'start' | 'end', timeField: keyof ITimeVal, value: number) => {
      const newSchedule = { ...schedule };
      if (newSchedule[day] && newSchedule[day]?.[index]) {
        const ranges = [...(newSchedule[day] || [])];
        const updatedRange = { ...ranges[index] };
        updatedRange[field] = { ...updatedRange[field], [timeField]: value };
        ranges[index] = updatedRange;
        newSchedule[day] = ranges;
        setSchedule(newSchedule);
      }
    };

    const renderTimeRanges = (day: keyof ICallTime) => {
      return (
        <div style={{ marginBottom: '10px' }}>
          {schedule[day]?.map((timeRange, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <div style={{ marginRight: '10px' }}>
                <Label>Start</Label>
                <Input 
                  type="number" 
                  min={0} 
                  max={23} 
                  value={timeRange.start.hour} 
                  style={{ width: '60px', marginRight: '5px' }}
                  onChange={(e, d) => updateTimeRange(day, index, 'start', 'hour', parseInt(d.value || '0'))}
                /> : 
                <Input 
                  type="number" 
                  min={0} 
                  max={59} 
                  value={timeRange.start.minute} 
                  style={{ width: '60px' }}
                  onChange={(e, d) => updateTimeRange(day, index, 'start', 'minute', parseInt(d.value || '0'))}
                />
              </div>
              <div style={{ marginRight: '10px' }}>
                <Label>End</Label>
                <Input 
                  type="number" 
                  min={0} 
                  max={23} 
                  value={timeRange.end.hour} 
                  style={{ width: '60px', marginRight: '5px' }}
                  onChange={(e, d) => updateTimeRange(day, index, 'end', 'hour', parseInt(d.value || '0'))}
                /> : 
                <Input 
                  type="number" 
                  min={0} 
                  max={59} 
                  value={timeRange.end.minute} 
                  style={{ width: '60px' }}
                  onChange={(e, d) => updateTimeRange(day, index, 'end', 'minute', parseInt(d.value || '0'))}
                />
              </div>
              <Button 
                icon="trash" 
                color="red" 
                size="mini" 
                onClick={() => removeTimeRange(day, index)}
              />
            </div>
          ))}
          <Button size="mini" onClick={() => addTimeRange(day)}>
            Add Time Range
          </Button>
        </div>
      );
    };

    return (
      <Form>
        <Message info>
          <Message.Header>Call Schedule</Message.Header>
          <p>Configure the days and time ranges when calls are allowed to be made.</p>
        </Message>
        
        <div className="schedule-container">
          {/* Weekdays Group */}
          <h3>Weekdays</h3>
          <div className="ui grid">
            {days.slice(0, 5).map((day) => (
              <div key={day} className="eight wide column">
                <div className="ui segment" style={{ 
                  marginBottom: '15px', 
                  padding: '12px', 
                  border: '1px solid #ddd', 
                  borderRadius: '4px',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                  background: schedule[day]?.length ? '#f8f9fa' : '#fff'
                }}>
                  <h4 style={{ 
                    marginBottom: '10px', 
                    padding: '5px', 
                    borderBottom: '1px solid #eee', 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <span>{day}</span>
                    <span style={{ fontSize: '0.85em', color: '#888' }}>
                      {schedule[day]?.length ? `${schedule[day]?.length} time range(s)` : 'No time ranges'}
                    </span>
                  </h4>
                  {renderTimeRanges(day)}
                </div>
              </div>
            ))}
          </div>
          
          {/* Weekend Group */}
          <h3>Weekend</h3>
          <div className="ui grid">
            {days.slice(5).map((day) => (
              <div key={day} className="eight wide column">
                <div className="ui segment" style={{ 
                  marginBottom: '15px', 
                  padding: '12px', 
                  border: '1px solid #ddd', 
                  borderRadius: '4px',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                  background: schedule[day]?.length ? '#f8f9fa' : '#fff'
                }}>
                  <h4 style={{ 
                    marginBottom: '10px', 
                    padding: '5px', 
                    borderBottom: '1px solid #eee', 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <span>{day}</span>
                    <span style={{ fontSize: '0.85em', color: '#888' }}>
                      {schedule[day]?.length ? `${schedule[day]?.length} time range(s)` : 'No time ranges'}
                    </span>
                  </h4>
                  {renderTimeRanges(day)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Form>
    );
  };

  const panes = [
    {
      menuItem: "General",
      render: () => <Tab.Pane>{GeneralEditor()}</Tab.Pane>,
    },
    { menuItem: "Prompt", render: () => <Tab.Pane>{PromptEditor()}</Tab.Pane> },
    {
      menuItem: "Trailing Prompt",
      render: () => <Tab.Pane>{TrailingPromptEditor()}</Tab.Pane>,
    },
    { menuItem: "Script", render: () => <Tab.Pane>{ScriptEditor()}</Tab.Pane> },
    { menuItem: "SMS", render: () => <Tab.Pane>{SMSEditor()}</Tab.Pane> },
    {
      menuItem: "Static phrases",
      render: () => <Tab.Pane>{StaticPhrases()}</Tab.Pane>,
    },
    {
      menuItem: "Schedule",
      render: () => <Tab.Pane>{ScheduleEditor()}</Tab.Pane>,
    },
  ];
  const getActualConfig = useCallback((): IConfig => {
    return {
      name: configId,
      language: language,
      description: description,
      eventTypeId: eventTypeId,
      location: location,
      script: script,
      timezone: timezone,
      firstSend: sms1,
      secondSend: sms2,
      thirdSend: sms3,
      testLead: testLead,
      createdTime: Date.now().valueOf(),
      prompt: prompt,
      trailing_prompt: trailing_prompt,
      staticPhrases: InvTransfromPhrases(staticPhrases),
      speaker: speaker === "" ? undefined : speaker,
      voice_model: voice_model === "" ? undefined : voice_model,
      sourcePhone: sourcePhone === "" ? undefined : sourcePhone,
      schedulerEnabled: schedulerEnabled,
      schedule: schedule,
      regionPhonePrefix: regionPhonePrefix,
    };
  }, [
    configId,
    language,
    description,
    eventTypeId,
    location,
    script,
    timezone,
    sms1,
    sms2,
    sms3,
    testLead,
    prompt,
    trailing_prompt,
    staticPhrases,
    speaker,
    voice_model,
    sourcePhone,
    schedulerEnabled,
    schedule,
    regionPhonePrefix,
  ]);

  const makeCall = useCallback(
    (config: IConfig, endpoint: string | undefined) => {
      setError(undefined);
      const callAsync = async () => {
        await sms.Load();
        const ep = endpoint ?? (await sip.initialize());
        await fetch("/api/v1/call", {
          method: "POST",
          body: JSON.stringify({ config: config, endpoint: ep }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setIsCalling(true);
        new Promise((resolve) =>
          setTimeout(() => resolve(true), 5000)
        ).then(() => setIsCalling(false));
      };
      callAsync().catch((e) => setError(e.message));
    },
    [sip, sms]
  );

  return (
    <>
      <h1>{configId}</h1>
      <Checkbox
        toggle
        checked={schedulerEnabled}
        onChange={(e, d) => setSchedulerEnabled(d.checked ?? false)}
        label={"Scheduler enabled"}
      ></Checkbox>
      <Tab panes={panes}></Tab>

      <Button onClick={() => configs.SaveAsync(getActualConfig()).then(() => setError(undefined)).catch((e) => setError(e.message))}>Save</Button>

      <Modal
        trigger={<Button onClick={() => setSaveAsOpen(true)}>Save as</Button>}
        onClose={() => setSaveAsOpen(false)}
        open={saveAsOpen}
      >
        <Modal.Content>
          <Form>
            <Form.Field>
              <Label>Name</Label>
              <Input
                onChange={(e, d) => setSaveAsName(d.value)}
                value={saveAsName}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setSaveAsOpen(false)} negative>
            Cancel
          </Button>
          <Button
            disabled={saveAsName === "" || configs.Has(saveAsName)}
            onClick={() => {
              configs
                .SaveAsync({
                  ...getActualConfig(),
                  ...{ name: saveAsName },
                })
                .then(() => setError(undefined))
                .then(() => navigate(`/configs/${saveAsName}`))
                .catch((e) => setError(e.message));
            }}
            positive
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>

      {!sip.isConnected && (
        <Button
          onClick={() => makeCall(getActualConfig(), undefined)}
          positive
          disabled={isCalling}
        >
          Call to browser
        </Button>
      )}
      <Form.Field>
        <Input
          placeholder="+49...."
          value={toCallNumber}
          onChange={(e, d) => setToCallNumber(d.value)}
        />

        <Button
          positive
          attached="left"
          disabled={isCalling}
          onClick={() => makeCall(getActualConfig(), toCallNumber)}
        >
          {isCalling ? "Ringing" : "Call to phone number"}
        </Button>
      </Form.Field>
      {sip.isConnected && (
        <Button onClick={() => sip.hangup()} negative>
          Hangup
        </Button>
      )}
      {error && <Message negative>{error}</Message>}

      {sms.Messages.length > 0 && (
        <Message>
          Received SMS to `{sms.Messages.at(-1)?.phone}`
          <br />
          {sms.Messages.at(-1)?.body}
          <Input
            placeholder="Your reply"
            onChange={(e, d) => setSMSReply(d.value)}
            value={smsReply}
          />
          <Button
            onClick={() =>
              sms.reply(sms.Messages.at(-1)?.phone ?? "", smsReply)
            }
          >
            Reply
          </Button>
        </Message>
      )}
      <div>
        <Label>Latest results will be here after call</Label>
        {lastMockData && (
          <>
            <a
              href={`https://playground.dasha.ai/inspector/${lastMockData.id}`}
              target="_blank"
            >
              Inspect
            </a>
            <TextArea
              rows={20}
              style={{ width: "100%" }}
              value={JSON.stringify(lastMockData.results, null, 4)}
            ></TextArea>
          </>
        )}
      </div>
    </>
  );
});

export const ConfigEditor: FC<{}> = observer(() => {
  const { configs } = useStore();
  const { configId } = useParams();
  const [config, setConfig] = useState<IConfig | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();
  useEffect(() => {
    setLoading(true);
    configs
      .Load()
      .then(() => setConfig(configs.Get(configId!)))
      .finally(() => setLoading(false));
  }, [configId]);

  if (loading) {
    return <Loader>Loading configs</Loader>;
  }
  if (
    config !== undefined &&
    config !== null &&
    configId !== null &&
    configId !== undefined
  ) {
    return (
      <>
      {error && <Message negative>{error}</Message>}
      <ConfigEditorInternal
        config={config!}
        configId={configId}
        error={error}
        setError={setError}
      /></>
    );
  }

  return <h1>Config not found</h1>;
});
